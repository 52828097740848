import { getCurrentInstance } from "vue";

export default () => {
  let styleSheets = new Map();
  let index = 0;

  const createStyleSheet = async (content) => {
    let newSheet = false;
    if (utilities.isSSR()) {
      utilities.warn(
        "useTempStylesheet.createStyleSheet: SSR detected, creating a dummy",
        1
      );
    } else {
      newSheet = document.createElement("style");
      document.head.appendChild(newSheet);
      newSheet.textContent = content;
      await utilities.wait(50);
    }

    styleSheets[index] = newSheet;
    index++;
    return index - 1;
  };

  const destroyStyleSheet = (id) => {
    let target = styleSheets[id];

    if (typeof target === "undefined") {
      utilities.warn(
        "useTempStylesheet.destroyStyleSheet: styleSheet not found",
        1
      );
      return false;
    }

    // case of SSR
    if (target === false) {
      return true;
    }

    target.parentNode.removeChild(target);
    delete styleSheets[id];
    return true;
  };

  const activateStyleSheet = (id) => {
    let target = styleSheets[id];
    if (typeof target === "undefined") {
      utilities.warn(
        "useTempStylesheet.activateStyleSheet: styleSheet not found",
        1
      );
      return false;
    }

    // case of SSR
    if (target === false) {
      return false;
    }

    target.sheet.disabled = false;
    return true;
  };

  const deactivateStyleSheet = (id) => {
    let target = styleSheets[id];

    if (typeof target === "undefined") {
      utilities.warn(
        "useTempStylesheet.deactivateStyleSheet: styleSheet not found",
        1
      );
      return false;
    }

    // case of SSR
    if (target === false) {
      return false;
    }

    target.sheet.disabled = true;
    return true;
  };

  return {
    // our api, support both styleSheet and stylesheet (notice capital S)
    createStyleSheet,
    createStylesheet: createStyleSheet,
    destroyStyleSheet,
    destroyStylesheet: destroyStyleSheet,
    activateStyleSheet,
    activateStylesheet: activateStyleSheet,
    deactivateStyleSheet,
    deactivateStylesheet: deactivateStyleSheet,
    // proxy for deactivate
    disableStyleSheet: deactivateStyleSheet,
    disableStylesheet: deactivateStyleSheet,
  };
};
